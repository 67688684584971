#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1.5rem auto;
}

.skillTitle {
    font-size: 3rem;
    font-weight: 600;
    padding-top: 1rem;
    margin-bottom: 1.5rem;
}

.skillDescription {
    font-weight: 300;
    font-size: 0.9rem;
    max-width: 50rem;
    padding: 0 2rem;
}

.skillBars {
    margin: 1.5rem;
    width: 100vw;
    max-width: 90%;
    text-align: left;
}

.skillBar {
    display: flex;
    margin: 1rem;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    background: rgb(50,50,50);
    border: 2.5px solid #383737;
    transition: transform 0.3s ease;
}

.skillBar:hover {
    transform: translateY(-5px);
}

.skillBarImg {
    object-fit: cover;
    height: 5rem;
    width: 5rem;
    margin-right: 2rem;
    border-radius: 20%;
    border: 5px solid #ddd;
}

.skillBarText>p {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    font-weight: 200;

}

@media screen and (max-width:720px) {
    .skillBarText>h2 {
        font-size: 6vw;
    }
    .skillBarText>p {
        font-size: 3.3vw;
        font-weight: 10;
    }
    .skillBars {
        flex-direction: column;
        align-self: flex-start;
        padding: 0rem 0rem;

    }
    .skillBarImg {
        padding-right: 0;
    }

    .skillBarImg {
        height: 2.5rem;
        width: 2.5rem;
    }
}
