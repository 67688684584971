#works {
    margin: 0 auto;
    min-height: calc(100vh-45px);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.worksTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDescription {
    font-weight: 300;
    font-size: 0.9rem;
    max-width: 45rem;
}

.worksImgs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 65rem;
}

.worksImg {
    object-fit: cover;
    height: 18rem;
    width: 18rem;
    margin: 1.5rem ;
    border-radius: 20%;
    border: 5px solid #ddd;
}

.workDesc {
    font-size: 0.75rem;
    color: white;
}

.worksBtn {
    padding: 0.5rem 2.5rem;
    background: white;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    margin: 3rem 0rem;
}

@media screen and (max-width:585px) {
    .worksImg {
        height: 48vw;
    }
    
}
