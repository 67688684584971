#intro {
    height: calc(100vh - 45px);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    
}

.btn {
    margin: 1rem 0rem;
    padding: 0.5rem 1rem;
    background-color: white;
    color: black;
    border: none;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 120px;
}

.btnImg{
    object-fit: cover;
    margin-right: 0rem 0.25rem;
    width: 1.8rem;
    height: 1.5rem;

}

.bg {
    position: absolute; 
    z-index: -1;
    top: -10rem;
    right: -35rem;
    object-fit: cover;
    height: 100vh;

}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Yo{
    font-size: 1.75rem;
    font-weight: 100;
}

.introName{
    color:red;
}

.introSubtext{
    font-size: 50%;
    margin: 0 10px;
}

.introPara{
    font-size: medium;
    font-weight: 300;
}

@media screen and (max-width:840px) {
    .bg {
        left: 2vw
    }
    .introContent {
        font-size: 10vw;
    }
    .Yo {
        font-size: 4.5vw;
    }
}
