#contactPage {
    min-height: calc(100vh -45px);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactPageTitle {
    font-size: 3rem;
    font-weight: 600;
    padding-top: 1rem;
    margin-bottom: 1.5rem;
}

.contactDescription {
    padding: 1rem;
    font-size: 0.9rem;
    font-weight: 300;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border: 0.5rem;
    background: rgb(40, 40, 40);
}

.submitBtn {
    background: white;
    border: none;
    padding: 0.75rem 3.5rem;
    margin: 2rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.submitBtn:hover {
    background-color: white}

.submitBtn:active {
  background-color: white;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.links {
    display: flex;
    flex-wrap: wrap;
}

.link {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 0.75rem;
    border-radius: 20%;
    border: 5px solid #ddd;
    cursor: pointer;
}
